module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ely-exotics","short_name":"ely-exotics","start_url":"/","background_color":"#F2F7E9","theme_color":"#272D3D","display":"minimal-ui","icon":"src/images/eye-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cd882e06591abdc3af804259c4092908"},
    },{
      plugin: require('../node_modules/@awolf81/gatsby-theme-addthis/gatsby-browser.js'),
      options: {"plugins":[],"publicId":"ra-602140e286141d63"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
