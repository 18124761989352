// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-care-guide-index-jsx": () => import("./../../../src/templates/care-guide/index.jsx" /* webpackChunkName: "component---src-templates-care-guide-index-jsx" */),
  "component---src-templates-care-guide-listings-jsx": () => import("./../../../src/templates/care-guide/listings.jsx" /* webpackChunkName: "component---src-templates-care-guide-listings-jsx" */),
  "component---src-templates-categories-animals-and-products-listings-jsx": () => import("./../../../src/templates/categories/animals-and-products-listings.jsx" /* webpackChunkName: "component---src-templates-categories-animals-and-products-listings-jsx" */),
  "component---src-templates-categories-category-listings-jsx": () => import("./../../../src/templates/categories/category-listings.jsx" /* webpackChunkName: "component---src-templates-categories-category-listings-jsx" */),
  "component---src-templates-categories-sub-category-listings-jsx": () => import("./../../../src/templates/categories/sub-category-listings.jsx" /* webpackChunkName: "component---src-templates-categories-sub-category-listings-jsx" */),
  "component---src-templates-posts-index-jsx": () => import("./../../../src/templates/posts/index.jsx" /* webpackChunkName: "component---src-templates-posts-index-jsx" */),
  "component---src-templates-posts-listings-jsx": () => import("./../../../src/templates/posts/listings.jsx" /* webpackChunkName: "component---src-templates-posts-listings-jsx" */),
  "component---src-templates-posts-taxonomy-jsx": () => import("./../../../src/templates/posts/taxonomy.jsx" /* webpackChunkName: "component---src-templates-posts-taxonomy-jsx" */),
  "component---src-templates-single-page-index-jsx": () => import("./../../../src/templates/single-page/index.jsx" /* webpackChunkName: "component---src-templates-single-page-index-jsx" */)
}

